import { ethers } from 'ethers';
import registrar from './build/contracts/RootRegistry.json';
import detectEthereumProvider from '@metamask/detect-provider';
import { customToast, toast } from 'components/common/Toast';
import domainMetadata from './build/contracts/DomainMetadata.json';
import metadata from './build/contracts/Metadata.json';
import PaymentMetadata from './build/contracts/PaymentMetadata.json';

import UniversalProvider from "@walletconnect/universal-provider";
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { WalletConnectModal } from '@walletconnect/modal';
import { signTypedData } from '@wagmi/core';
import { openWallet } from './qi';

const modal = new WalletConnectModal({
  projectId: '2c504daea9f8cd270f5a3e92fb8bcf41',
  chains: ['eip155:5656'],
  explorerRecommendedWalletIds:
    [
      'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
      '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369',
      '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    ]
})

const QIEmodal = new WalletConnectModal({
  projectId: '2c504daea9f8cd270f5a3e92fb8bcf41',
  chains: ['eip155:5656'],
  explorerRecommendedWalletIds:
    [

    ]
})

let _globalProvider = null;

const ethereum = window.ethereum;
const gas = 100000000;

export const shortenAddress = address => {
  if (address) {
    return address.slice(0, 5) + '...' + address.slice(-4);
  } else {
    return '---';
  }
};

const getMetamaskProvider = async () => {
  const provider = await detectEthereumProvider();

  return new ethers.providers.Web3Provider(provider);
};

const getQIEWalletProvider = async () => {

  setInterval(async function () {
    const resp = window.qie.ping();
  }, 20000);

  if (window.qie)
    return new ethers.providers.Web3Provider(window.qie);
  else if (window.ethereum && !window.ethereum.isMetaMask)
    return new ethers.providers.Web3Provider(window.ethereum);
};

const getWalletConnectProvider = async () => {
  const provider = await UniversalProvider.init({
    logger: "info",
    relayUrl: "wss://relay.walletconnect.com",
    projectId: "2c504daea9f8cd270f5a3e92fb8bcf41",
  });
  provider.on("display_uri", async (uri) => {
    console.log("display_uri", uri);
    await modal.openModal({
      uri: uri
    })
  });

  const session = await provider.connect({
    namespaces: {
      eip155: {
        methods: [
          "eth_chainId",
          "eth_sendTransaction",
          "eth_signTransaction",
          "eth_sign",
          "personal_sign",
          "eth_signTypedData",
          "eth_signTypedData_v4"
        ],
        optionalMethods: ['eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign'],
        chains: ['eip155:5656'],
        events: ["chainChanged", "accountsChanged"],
        rpcMap: { 5656: `https://rpc-main1.qiblockchain.online`, },
        defaultChain: '5656'
      },
    },
    skipPairing: false,
  });


  const account = await provider.enable();
  console.log("_accounts", account);
  modal.closeModal()

  console.log("----------", session);
  return new ethers.providers.Web3Provider(provider);
}

const getWalletConnectProviderForQIE = async () => {
  const provider = await UniversalProvider.init({
    logger: "info",
    relayUrl: "wss://relay.walletconnect.com",
    projectId: "2c504daea9f8cd270f5a3e92fb8bcf41",
  });
  provider.on("display_uri", async (uri) => {
    console.log("display_uri", uri);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      window.open('qiemobilewalletconnect://' + uri, '_blank')
    } else {
      await QIEmodal.openModal({
        uri: uri
      })
    }
  });

  const session = await provider.connect({
    namespaces: {
      eip155: {
        methods: [
          "eth_chainId",
          "eth_sendTransaction",
          "eth_signTransaction",
          "eth_sign",
          "personal_sign",
          "eth_signTypedData",
          "eth_signTypedData_v4"
        ],
        optionalMethods: ['eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign'],
        chains: ['eip155:5656'],
        events: ["chainChanged", "accountsChanged"],
        rpcMap: { 5656: `https://rpc-main1.qiblockchain.online`, },
        defaultChain: '5656'
      },
    },
    skipPairing: false,
  });


  const account = await provider.enable();
  console.log("_accounts", account);
  modal.closeModal()

  console.log("----------", session);
  return new ethers.providers.Web3Provider(provider);
}

export const getWeb3Provider_a = async () => {
  if (!_globalProvider) {
    const provider = await EthereumProvider.init({
      projectId: "2c504daea9f8cd270f5a3e92fb8bcf41", // REQUIRED your projectId
      chains: ['1618'], // REQUIRED chain ids
      optionalChains: [], // OPTIONAL chains
      showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal
      methods: [
        "eth_sendTransaction",
        "eth_signTransaction",
        "eth_sign",
        "personal_sign",
        "eth_signTypedData", ,
        "eth_signTypedData_v4"
      ], // REQUIRED ethereum methods
      events: ["chainChanged", "accountsChanged"],
      rpcMap: { 1618: `https://rpc-main1.qiblockchain.online`, },
    })

    await provider.connect()

    _globalProvider = new ethers.providers.Web3Provider(provider);
    return _globalProvider;
  } else {
    return _globalProvider;
  }
};

export const getWeb3Provider = async () => {
  try {
    if (!_globalProvider) {
      console.log(window.selectedWallet);
      if (window.selectedWallet === 'qie') {
        if (!window.qie && (window.ethereum && window.ethereum.isMetaMask)) {
          customToast({
            title: 'Error',
            description: 'QIE Wallet not found! Please install QIE Wallet.',
            status: 'error',
          });
        } else {
          _globalProvider = await getQIEWalletProvider();
        }
      }
      else if (window.selectedWallet === 'qiemobile') {
        _globalProvider = await getWalletConnectProviderForQIE();
      }
      else if (window.selectedWallet === 'metamask') {
        if (window.ethereum && window.ethereum.isMetaMask) {
          _globalProvider = await getMetamaskProvider();
        } else {
          customToast({
            title: 'Error',
            description: 'Wallet not found! Please install Metamask.',
            status: 'error',
          });
        }
      }
      else if (window.selectedWallet === 'walletconnect') {
        _globalProvider = await getWalletConnectProvider();
      }
      console.log("00000000000000000", _globalProvider)
      if (!_globalProvider) return new Error("Error Getting wallet!")
      // await checkChainId('0x1618');
      console.log("+++++++++++++")
      return _globalProvider;
    } else {
      console.log("getting cached provider");
      return _globalProvider;
    }
  } catch (e) {
    console.log(e);
  }

  // return new ethers.providers.Web3Provider(provider);
};

export const getWeb3Account = async provider => {
  try {
    const accounts = await provider.send('eth_requestAccounts', []);
    return accounts[0];
  } catch (error) {
    console.error('getWeb3Account', error?.message);
    return null;
  }
};

export const checkChainId = async (chainId) => {
  if (window.selectedWallet === "walletconnect" || window.selectedWallet === "qiemobile") {
    if ((await _globalProvider.send('eth_chainId', [])) !== chainId) {
      try {
        return await _globalProvider.send('wallet_switchEthereumChain', [{ chainId }]);
      } catch (switchError) {
        if (switchError.code === 4902) {
          console.error('This network is not available in your metamask.');
        }
        console.error('Failed to switch to the network');
        return false;
      }
    }
  } else if (window.selectedWallet === "qie") {
    if ((await window.qie.request({ method: 'eth_chainId' })) !== Number(chainId)) {
      try {
        const chainChange = await window.qie.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
        return chainChange === null ? true : false;
      } catch (switchError) {
        if (switchError.code === 4902) {
          console.error('This network is not available in your metamask.');
        }
        console.error('Failed to switch to the network');
        return false;
      }
    }
  } else {
    if ((await ethereum.request({ method: 'eth_chainId' })) !== chainId) {
      try {
        return await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          console.error('This network is not available in your metamask.');
        }
        console.error('Failed to switch to the network');
        return false;
      }
    }
  }
};

const getCurrentChainId = async () => {
  const currentChainId = await _globalProvider.send('eth_chainId');

  return currentChainId;
};

export const addNetworkToWallet = async networkDetails => {
  try {
    console.log("-----------add network")
    const toastId1 = 'add-wallet-network';
    const toastId2 = 'existing-wallet-network';

    if (_globalProvider) {
      const currentChainId = await getCurrentChainId();

      if (currentChainId === networkDetails.chainId) {
        if (!toast.isActive(toastId2)) {
          customToast({
            id: toastId2,
            description: `You are already using ${networkDetails.chainName} network!`,
            status: `error`,
          });

          return;
        }
      }

      await _globalProvider.send('wallet_addEthereumChain', [networkDetails]);

      const newChainId = await getCurrentChainId();

      if (currentChainId !== newChainId) {
        if (!toast.isActive(toastId1)) {
          customToast({
            id: toastId1,
            description: `Network switched to ${networkDetails.chainName} successfully!`,
            status: `success`,
          });
        }
      } else {
        if (!toast.isActive(toastId1)) {
          customToast({
            id: toastId1,
            description: `Something went wrong while switching to ${networkDetails.chainName} network!`,
            status: `error`,
          });
        }
      }
    }
  } catch (error) {
    console.error('addNetworkToWallet =>', error?.message);
  }
};

export const signDomainMintTransaction = async ({ domain }) => {
  const domainNetwork = domain?.tld?.network;

  if (!domainNetwork) {
    throw Error("Can't find domainNetwork");
  }

  const domainValues = domain?.domainName?.split('.');

  const provider = await getWeb3Provider();
  const signer = provider.getSigner();

  const account = await signer.getAddress();

  const salt = Math.round(Math.random() * 1000000);

  const message = 'Approve Nexbloc to mint domain to your wallet';

};

const toTypedOrder = (
  domain_owner,
  domain_parent,
  domain_label,
  domain_zone,
  domain_salt,
  domain_message,
  chainId,
  verifyingContract,
  domainName = 'QI',
) => {
  const domain = {
    name: domainName,
    version: '1',
    chainId,
    verifyingContract,
  };

  const types = {
    Order: [
      { name: 'owner', type: 'address' },
      { name: 'parent', type: 'string' },
      { name: 'label', type: 'string' },
      { name: 'zone', type: 'string' },
      { name: 'salt', type: 'uint256' },
      { name: 'message', type: 'string' },
    ],
  };

  const value = {
    owner: domain_owner,
    parent: domain_parent,
    label: domain_label,
    zone: domain_zone,
    salt: domain_salt,
    message: domain_message,
  };

  return { domain, types, value };
};

export const getSignature = async (signer, ...args) => {
  try {
    const order = toTypedOrder(...args);

    console.log("toTypedOrder");

    const signedTypedHash = await signer._signTypedData(
      order.domain,
      order.types,
      order.value,
    );

    //   const signedTypedHash = await signTypedData({
    //     domain: order.domain,
    //     message: order.value,
    //     primaryType: 'Mail',
    //     types: order.types,
    // });

    console.log("signed")

    const sig = ethers.utils.splitSignature(signedTypedHash);

    console.log("splitSignature")

    return [sig.v, sig.r, sig.s];
  } catch (e) {
    console.log("getSignature", e);
  }
};

export const saveDomainMetadata = async (
  domainName,
  metadataFields,
  metadataValue,
) => {
  const metadataContractAddress =
    process.env.REACT_APP_DOMAIN_METADATA_CONTRACT_ADDRESS;

  const chainId = '0x1618';

  await checkChainId(chainId);

  const provider = await getWeb3Provider();
  const signer = provider.getSigner();
  const account = await signer.getAddress();

  const metadataContractInstance = new ethers.Contract(
    metadataContractAddress,
    domainMetadata.abi,
    signer,
  );

  if (!metadataContractInstance) {
    throw Error('metadataContractInstance is undefined');
  }

  openWallet();
  return await metadataContractInstance.updateMetadata(
    domainName,
    metadataFields,
    metadataValue,
  );
};
//TODO fix this also
export const getDomainTokenId = async domainName => {
  const REGISTRAR_CONTRACT_ADDRESS =
    process.env.REACT_APP_QI_REGISTRAR_CONTRACT_ADDRESS;
  const provider = await getWeb3Provider();
  const signer = provider.getSigner();

  let registrarInstance = new ethers.Contract(
    REGISTRAR_CONTRACT_ADDRESS,
    registrar.abi,
    signer,
  );
  const tx = await registrarInstance.domainInfo(domainName);
  console.log(tx);
  return tx[1];
};

export const getTokenId = async domainName => {
  const REGISTRAR_CONTRACT_ADDRESS =
    process.env.REACT_APP_QI_REGISTRAR_CONTRACT_ADDRESS;
  const provider = await getWeb3Provider();
  const signer = provider.getSigner();

  let registrarInstance = new ethers.Contract(
    REGISTRAR_CONTRACT_ADDRESS,
    registrar.abi,
    signer,
  );
  const tx = await registrarInstance.resolver(domainName);
  return tx[0];
};

export const getDomainAddress = async (domain, wallet) => {
  try {
    const provider = await getWeb3Provider();
    const signer = provider.getSigner();

    await checkChainId('0x1618');

    const metadataContractInstance = new ethers.Contract(
      process.env.REACT_APP_DOMAIN_METADATA_CONTRACT_ADDRESS,
      metadata.abi,
      signer,
    );

    const tx = await metadataContractInstance.resolveCurrency(domain, 'qie');

    if (tx)
      return { domain: domain, wallet: wallet, address: tx };

    let registrarInstance = new ethers.Contract(
      process.env.REACT_APP_QI_REGISTRAR_CONTRACT_ADDRESS,
      registrar.abi,
      signer,
    );

    const address = await registrarInstance.resolver(
      domain
    );
    // const address = await tx.wait();
    console.log(address);
    return { domain: domain, wallet: wallet, address: address[1] };
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const addPaymentAddresses = async (domain, metaData) => {
  try {
    const provider = await getWeb3Provider();
    const signer = provider.getSigner();

    const chainId = '0x1618';

    await checkChainId(chainId);

    const metadataContractInstance = new ethers.Contract(
      process.env.REACT_APP_DOMAIN_METADATA_CONTRACT_ADDRESS,
      metadata.abi,
      signer,
    );

    const metaKeys = metaData.map(item => item.wallet.toLowerCase());
    const metaValues = metaData.map(item => item.address);

    if (!metadataContractInstance) {
      throw Error('metadataContractInstance is undefined');
    }

    openWallet();
    const tx = await metadataContractInstance.addAddress(domain, metaKeys, metaValues, {
      gasLimit: 5200000,
    });

    const response = await tx.wait();
    return response;
  } catch (e) {
    console.log(e);
    return {};
  }
};

// 0xA83f5FEb5c0E5E40e6d1Fb1ffD7dD6495fF9ceb8
