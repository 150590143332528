import React, { useState } from 'react';
import {
  Box,
  Text,
  Stack,
  Button,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  IconButton,
  Circle,
  useDisclosure,
} from '@chakra-ui/react';
import Logo, { MobileLogo } from 'components/common/Logo';
import useViewport from 'utils/Hooks/useViewPort';
import { FiCornerRightUp, FiDollarSign, FiPhone, FiLogOut } from 'react-icons/fi';
import { BiUser, BiUserCircle } from 'react-icons/bi';
import { AiOutlineShoppingCart, AiOutlineHeart } from 'react-icons/ai';
import { TfiMenuAlt } from "react-icons/tfi";
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SidebarDrawer from './SidebarDrawer';
import { useHistory } from 'react-router-dom';
import { customToast } from '../../../components/common/Toast';
import LogoutAlert from './LogoutAlert';
import ConnectWallet from 'components/Wallet/ConnectWallet';
import { getUser } from 'pages/User/userEntity';
import { isLoggedIn } from 'utils/Auth';
import VerifyEmailBanner from './VerifyEmailBanner';


const NavItems = [];

export const NavWebItems = ({ name, path }) => (
  <Button variant="ghost" color="brand.600" as={RouterLink} to={path}>
    {name}
  </Button>
);

export const NavMobileItems = ({ name }) => (
  <Box fontSize="md" color="brand.400">
    {name}
  </Box>
);

function SignInButton() {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const logout = () => {
    localStorage.clear();
    customToast({ title: 'Success', description: 'Logged Out' });
    onClose();
    history.push('/login');
  };

  return (
    <>
      <LogoutAlert onClose={onClose} onOk={logout} isOpen={isOpen} />
      {localStorage.getItem('token') ? (
        <Menu>
          <MenuButton name="profile-button" display="flex" flex-direction="row">
            <BiUserCircle size="36px" />
          </MenuButton>
          <MenuList zIndex={5}>
            <MenuItem>
              <RouterLink to="/profile/view">
                <HStack>
                  <BiUser /> 
                  <Text>My Account</Text>
                </HStack>
              </RouterLink>
            </MenuItem>

            <MenuItem>
              <RouterLink to="/app/my-domains">
                <HStack>
                  <TfiMenuAlt />
                  <Text>My Domains</Text>
                </HStack>
              </RouterLink>
            </MenuItem>

            <MenuItem>
              <RouterLink to="/transactions">
                <HStack>
                  <FiDollarSign />
                  <Text>Domain Transactions</Text>
                </HStack>
              </RouterLink>
            </MenuItem>

            <MenuItem>
              <RouterLink to="/support">
                <HStack>
                  <FiPhone />
                  <Text>Support</Text>
                </HStack>
              </RouterLink>
            </MenuItem>

            <MenuItem color='brand.600' onClick={onOpen}>
              <HStack>
                <FiLogOut />
                <Text>Logout</Text>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <>
          <Button
            as={RouterLink}
            to="/login"
            background={'transparent'}
            _hover={{ background: 'transparent' }}
            _active={{ background: 'transparent' }}
            color='brand.600'
            fontWeight='normal'
          >
            Sign In
          </Button>
          <Button
            as={RouterLink}
            to="/signup"
            background={'transparent'}
            _hover={{ background: 'transparent' }}
            _active={{ background: 'transparent' }}
            color='brand.600'
            fontWeight='normal'
          >
            Sign Up
          </Button>
        </>
      )}
    </>
  );
}

function MobileNav() {
  const cartDetails = useSelector(state => state?.entities?.cartDetails);
  const wishlist = useSelector(state => state?.entities?.wishlist);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      // px="2"
      // py="2"
      boxShadow="md"
      background='black'
    >
      <Stack direction="row">
        <Logo />
      </Stack>
      <HStack>
        {localStorage.getItem('token') && <ConnectWallet />}
        <SignInButton />
        <IconButton
          as={RouterLink}
          to="/wishlist"
          border='unset'
          fontSize='30px'
          background='transparent'
          icon={
            <>
              <AiOutlineHeart color='#ffff' />
              <Circle
                as={'span'}
                color={'#fff'}
                position={'absolute'}
                top={'6px'}
                right={'4px'}
                bgColor={'#ff136d'}
                zIndex={1}
                fontSize="xs"
                size="12px"
                padding='7px'
              >
                {wishlist?.length}
              </Circle>
            </>
          }
        />
        <IconButton
          as={RouterLink}
          to="/cart"
          border='unset'
          fontSize='30px'
          background='transparent'
          icon={
            <>
              <AiOutlineShoppingCart color='#ffff' />
              <Circle
                as={'span'}
                color={'#fff'}
                position={'absolute'}
                top={'6px'}
                right={'4px'}
                bgColor={'#ff136d'}
                zIndex={1}
                fontSize="xs"
                size="12px"
                padding='7px'
              >
                {cartDetails?.length}
              </Circle>
            </>
          }
        />

        {/* <SidebarDrawer /> */}
      </HStack>
    </Stack>
  );
}

function DesktopNav() {
  const cartDetails = useSelector(state => state?.entities?.cartDetails);
  const wishlist = useSelector(state => state?.entities?.wishlist);
  
  const [showEmailVerifyBanner, setShowEmailVerifyBanner] = useState(true);
  const user = useSelector(getUser);

  return (
    <Box position="relative">
      <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bgImage="url('/images/Grains-min.jpg')" 
      bgPosition="center"
      bgRepeat="repeat"
      bgSize="cover"
      opacity={.9}
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgColor: "#121422",
        opacity: 0.9,  
      }}
      _after={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgColor: "#383638",
        opacity: .1,  
        zIndex: -1,  
      }}
    >
    </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        px={{ sm: '2', lg: '10' }}
        py="2"
        position='relative'
      >
        <Stack direction="row" alignItems="center">
          {/* <SidebarDrawer /> */}
          <Logo />
        </Stack>
        <Stack direction="row" spacing="4">
          {(user?.email && !user?.verified && isLoggedIn() && showEmailVerifyBanner) && <VerifyEmailBanner closeBanner={() => { setShowEmailVerifyBanner(false) }} />}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ base: '2', lg: '4' }}
        >

          <Button
            as={RouterLink}
            to="/transfer"
            // leftIcon={<FiCornerRightUp fontSize="20px" />}
            // variant="outline"
            background={'transparent'}
            fontWeight='normal'
            _hover={{ background: 'transparent' }}
            _active={{ background: 'transparent' }}
            color='#ffff'
          >
            Crypto Transfer
          </Button>

          {localStorage.getItem('token') && <ConnectWallet />}
          <SignInButton />
          <Button
            as={RouterLink}
            to="/wishlist"
            position='relative'
            leftIcon={
              <div style={{ position: 'relative' }}>
                <AiOutlineHeart
                  fontSize="30px"
                />
                <div style={{ position: 'absolute', top: '-5px', right: '-5px', padding: '2px 6px', borderRadius: '25px', background: '#ff136d', fontSize: '12px' }}>{wishlist?.length}</div>
              </div>
            }
            // rightIcon={<Badge fontSize="1rem">{wishlist?.length}</Badge>}
            // variant="outline"
            background='transparent'
            _hover={{ background: 'transparent' }}
            _active={{ background: 'transparent' }}
          />
          <Button
            as={RouterLink}
            to="/cart"
            leftIcon={
              <div style={{ position: 'relative' }}>
                <AiOutlineShoppingCart fontSize='30px' />
                <div style={{ position: 'absolute', top: '-5px', right: '-5px', padding: '2px 6px', borderRadius: '25px', background: '#ff136d', fontSize: '12px' }}>{cartDetails && cartDetails.length}</div>
              </div>
            }
            // rightIcon={
            //   <Badge fontSize="1rem">{cartDetails && cartDetails.length}</Badge>
            // }
            background='transparent'
            _hover={{ background: 'transparent' }}
            _active={{ background: 'transparent' }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

export default function Nav() {
  const { width } = useViewport();
  const breakpoint = 1100;
  return width < breakpoint ? <MobileNav /> : <DesktopNav />;
}
